import { PropsWithChildren } from 'react';
import styled from 'styled-components';

import { Svg } from '../Svg';
import { SvgProperties } from '../Svg/Svg.types';

export const PathWrapper = styled.g``;

export const StyledSvg = styled(Svg)<PropsWithChildren<SvgProperties>>`
    cursor: ${({ onClick }) => (onClick ? 'pointer' : 'inherit')};
`;
