import styled from 'styled-components';

import { Heading } from '../../Heading';

export const StyledHeadingSubMenu = styled(Heading)`
    margin-bottom: 1rem;
    margin-left: 3rem;
    font-size: 1.9rem;
`;

export const StyledListSubMenuTop = styled.ul`
    margin: 0 0 2rem 0;
    padding-left: 0;
    list-style: none;
`;
