import { AnyContext } from '../cookies';
import { getFullUrlFromRequest, parseNextCachePathname } from '../url';
import { getOptimizelyUserId } from './cookie/featureFlagsCookie';
import {
    FlagsConstants,
    type FeatureFlagsRequestOptions,
} from './featureFlags.types';
import { optimizelyFlagsGetterTggWrapper } from './optimizely/optimizelyApi';
import { type OptimizelyFeatureFlags } from '@tgg/common-types';

export const getFeatureFlagRequestOptions = (
    context: AnyContext,
    options: FeatureFlagsRequestOptions,
) => {
    const { req } = context;
    if (!req) {
        return {
            userId: 'root',
            pathname: FlagsConstants.UNRESOLVED,
        };
    }

    const pathnameFromRequest = req.url
        ? getFullUrlFromRequest(req).pathname
        : FlagsConstants.UNRESOLVED;

    const pathname = pathnameFromRequest.includes('_next/data')
        ? parseNextCachePathname(pathnameFromRequest)
        : pathnameFromRequest;

    const userId = getOptimizelyUserId(context, options);

    return {
        pathname,
        userId,
    };
};

export const getAllFeatureFlagsSsr = async (
    context: AnyContext,
    options: FeatureFlagsRequestOptions,
) => {
    const { pathname, userId } = getFeatureFlagRequestOptions(context, options);

    const flagsObject = await optimizelyFlagsGetterTggWrapper('ALL', userId, {
        ...options,
        pathname,
    });

    return {
        flags: flagsObject.result,
        pathname,
        userId,
        error: flagsObject.error,
    };
};

export const getFeatureFlagSsr = async (
    context: AnyContext,
    key: OptimizelyFeatureFlags,
    options: FeatureFlagsRequestOptions,
) => {
    const { pathname, userId } = getFeatureFlagRequestOptions(context, options);

    const flagResult = await optimizelyFlagsGetterTggWrapper(key, userId, {
        ...options,
        pathname,
    });

    return {
        flag: flagResult.result,
        pathname,
        userId,
        error: flagResult.error,
    };
};
