import { useMemo } from 'react';

import { AppConfigContext } from './appConfigContext';
import { AppConfigProperties } from './appConfigContext.types';

export function AppConfigProvider({
    children,
    appConfig,
}: AppConfigProperties) {
    const contextValue = useMemo(() => {
        return { appConfig };
    }, [appConfig]);
    return (
        <AppConfigContext.Provider value={contextValue}>
            {children}
        </AppConfigContext.Provider>
    );
}
