import { palette } from '../../theme';
import { Icon } from '../Icon';
import { StyledWrapper } from './SocialIcon.styled';
import { SocialIconProperties } from './SocialIcon.types';

/**
 * Use the SocialIcon component to display a clickable social media icon.
 */
export const SocialIcon = ({
    variant,
    href,
    onClick,
}: SocialIconProperties) => {
    const label = `Visit our ${variant} page`;
    return (
        <StyledWrapper
            href={href}
            rel="noreferrer noopener"
            target="_blank"
            aria-label={label}
            onClick={() => onClick(label, href)}
        >
            <Icon name={variant} size={20} color={palette.common.white} />
        </StyledWrapper>
    );
};
