import { Divider } from '@material-ui/core';
import styled from 'styled-components';

import { Palette } from '../../theme';

export interface StyledHorizontalRuleProperties {
    variant: string;
    color: Palette;
}

export const StyledHorizontalRule = styled(
    Divider,
)<StyledHorizontalRuleProperties>`
    width: ${({ variant }) => (variant === 'inset' ? '100%' : 'initial')};
    background-color: ${({ color }) => color};
`;
