import { Slide, Paper } from '@material-ui/core';
import styled from 'styled-components';

import { palette, Theme } from '../../theme';
import { Heading } from '../Heading';
import { HorizontalRule } from '../HorizontalRule';

export const StyledPaper = styled.aside.attrs(() => ({
    tabIndex: -1,
}))`
    justify-content: space-between;
    width: 100%;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up(375)} {
        width: 37.5rem;
    }
`;

export const StyledSubMenuPaper = styled(Paper)<{ $isHeightAuto: boolean }>`
    height: ${({ $isHeightAuto }) => ($isHeightAuto ? 'auto' : 0)};
    box-shadow: none;
`;

export const StyledGrid = styled.div`
    display: grid;
    overflow: hidden;
`;

export const StyledMainMenu = styled.div`
    grid-row: 1;
    grid-column: 1;
`;
export const StyledList = styled.ul`
    margin: 0;
    margin-bottom: 2rem;
    padding-left: 0;
    list-style: none;
`;

export const StyledSlide = styled(Slide)`
    z-index: 1;
    grid-row: 1;
    grid-column: 1;
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
    width: 29rem;
    margin: 0 0 0 3rem;
    background-color: ${palette.grey['900']};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up(375)} {
        width: 34.5rem;
    }
`;

export const BackIconContainer = styled.div`
    display: flex;
    align-items: baseline;
    margin-bottom: 2rem;
    margin-left: 3.8rem;
    cursor: pointer;
`;

export const StyledHeadingMainMenu = styled(Heading)`
    margin-bottom: 1rem;
    margin-left: 3rem;
    font-size: 2.3rem;
    line-height: 3rem;

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        font-size: 2.6rem;
    }
`;

export const StyledHeadingBackLink = styled(Heading)`
    margin-left: 0.2rem;
    font-size: 1.5rem;
`;

export const StyledBackButton = styled.button`
    display: flex;
    align-items: start;
    width: 1.6rem;
    height: 1.6rem;
    margin-top: 0.6rem;
    padding: 0;
    background: transparent;
    border: none;
    cursor: pointer;
`;
