import { Drawer } from '@material-ui/core';
import { useRouter } from 'next/router';
import { useCallback, useState, useEffect } from 'react';

import { StickyNavigationButton } from '../StickyNavigationButton';
import { Header } from './Header';
import { MainNavMenu } from './MainNavMenu';
import { MemberAreaNavMenu } from './MemberAreaNavMenu';
import { PromotionalLinksList } from './PromotionalLinksList';
import {
    StyledPaper,
    StyledMainMenu,
    StyledGrid,
} from './SideNavigation.styled';
import {
    NavItemsWithSlide,
    RedirectionInfo,
    SideNavigationProperties,
} from './SideNavigation.types';
import { SlideGymListNavItems } from './SlideGymListNavItems';
import { SlideMemberAreaNavItems } from './SlideMemberAreaNavItems';
import { Promisable } from '@tgg/common-types';
import {
    EventKey,
    EventType,
    MenuLevelActionPayload,
    SideNavRedirectionData,
} from '@tgg/services';

const headerId = 'tgg-side-nav-header-element';

const scrollToTopContent = () => {
    //  When clicking to the sub-menu from the side navigation
    //  this is making sure the user will view the list from the top
    const sideNavHeaderElement = document.querySelector(`#${headerId}`);

    sideNavHeaderElement?.scrollIntoView();
};

const logAnalyticsMenuChange = async (
    slideMenuText: string,
    isOpen: boolean,
    isInMemberArea: boolean,
    logMenuChange: (
        eventType: EventType,
        data: MenuLevelActionPayload,
    ) => Promisable<void>,
) => {
    const menuLevelActionData = {
        menuText: slideMenuText.toLowerCase(),
        menuParent: slideMenuText.toLowerCase(),
        menuContext: isInMemberArea ? 'member area' : 'ecommerce',
    };

    const eventKey = isOpen ? EventKey.MENU_LEVEL_DOWN : EventKey.MENU_LEVEL_UP;

    logMenuChange(eventKey, menuLevelActionData);
};

const logAnalyticsSideNavRedirect = async (
    redirectionInfo: RedirectionInfo,
    isInMemberArea: boolean,
    logMenuChange: (
        eventType: EventType,
        data: SideNavRedirectionData,
    ) => Promisable<void>,
) => {
    const menuRedirectActionData = {
        menuText: redirectionInfo.menuItemText.toLowerCase(),
        menuParent: redirectionInfo.menuParentText.toLowerCase(),
        menuDestination: redirectionInfo.redirectUrl,
        menuContext: isInMemberArea ? 'member area' : 'ecommerce',
    };

    logMenuChange(EventKey.MENU_LINK, menuRedirectActionData);
};

export function SideNavigation({
    onClose,
    onCtaClick,
    open,
    stickyButton,
    hamBurgerHeading,
    menuItems,
    commonItemsHeadingText,
    logMenuChange,
    isInMemberArea,
}: SideNavigationProperties) {
    const router = useRouter();
    const [slideMenu, setSlideMenu] = useState<NavItemsWithSlide>({
        findAGym: false,
        myGym: false,
        classesBooked: false,
        membership: false,
        account: false,
    });

    const isASlideMenuOpen = Object.values(slideMenu).some(key => !!key);

    const handleSlideMenuChange = (
        slideMenuName: keyof NavItemsWithSlide,
        slideMenuText: string,
        isOpen: boolean,
    ) => {
        setSlideMenu({
            ...slideMenu,
            [slideMenuName]: isOpen,
        });

        logAnalyticsMenuChange(
            slideMenuText,
            isOpen,
            isInMemberArea,
            logMenuChange,
        );
    };

    const onBothMenuClose = useCallback(() => {
        onClose();
        setSlideMenu({
            findAGym: false,
            myGym: false,
            classesBooked: false,
            membership: false,
            account: false,
        });
    }, [onClose]);

    const handleOpenMemberAreaMenuItem = (
        navItemName: keyof NavItemsWithSlide,
        navItemText: string,
    ) => {
        handleSlideMenuChange(navItemName, navItemText, true);
        scrollToTopContent();
    };

    const handleChangeGymList = (isOpening: boolean) => {
        handleSlideMenuChange('findAGym', 'find a gym', isOpening);
        if (isOpening) {
            scrollToTopContent();
        }
    };

    useEffect(() => {
        router.events.on('routeChangeComplete', onBothMenuClose);

        return () => {
            router.events.off('routeChangeComplete', onBothMenuClose);
        };
    }, [onBothMenuClose, router.events]);

    return (
        <Drawer
            anchor="right"
            open={open}
            onClose={onBothMenuClose}
            PaperProps={{ component: StyledPaper }}
            ModalProps={{ keepMounted: true }}
        >
            <div data-testid="scrollable-wrapper-sidenav">
                <Header
                    id={headerId}
                    icon={hamBurgerHeading.icon}
                    text={hamBurgerHeading.text}
                    onClose={onBothMenuClose}
                />

                <StyledGrid>
                    <StyledMainMenu>
                        <PromotionalLinksList
                            items={menuItems.promotionalLinks}
                            isMenuOpen={isASlideMenuOpen}
                            logSideNavRedirect={(
                                redirectionInfo: RedirectionInfo,
                            ) =>
                                logAnalyticsSideNavRedirect(
                                    redirectionInfo,
                                    isInMemberArea,
                                    logMenuChange,
                                )
                            } //  Main menu
                        />

                        {'memberArea' in menuItems &&
                            menuItems.memberArea.length > 1 && (
                                <MemberAreaNavMenu
                                    items={menuItems.memberArea}
                                    onItemOpen={(navItemName, navItemText) =>
                                        handleOpenMemberAreaMenuItem(
                                            navItemName,
                                            navItemText,
                                        )
                                    }
                                    isMenuOpen={isASlideMenuOpen}
                                />
                            )}

                        <MainNavMenu
                            items={menuItems.mainMenu}
                            header={commonItemsHeadingText}
                            isMenuOpen={isASlideMenuOpen}
                            onGymListOpen={() => handleChangeGymList(true)}
                            logSideNavRedirect={(
                                redirectionInfo: RedirectionInfo,
                            ) =>
                                logAnalyticsSideNavRedirect(
                                    redirectionInfo,
                                    isInMemberArea,
                                    logMenuChange,
                                )
                            }
                        />
                    </StyledMainMenu>

                    <SlideGymListNavItems
                        items={menuItems.gymsList}
                        isOpen={slideMenu.findAGym}
                        onBack={() => handleChangeGymList(false)}
                        logSideNavRedirect={(
                            redirectionInfo: RedirectionInfo,
                        ) =>
                            logAnalyticsSideNavRedirect(
                                redirectionInfo,
                                isInMemberArea,
                                logMenuChange,
                            )
                        }
                    />

                    {'memberArea' in menuItems &&
                        menuItems.memberArea.length > 0 && (
                            <SlideMemberAreaNavItems
                                items={menuItems.memberArea}
                                onBack={(navItemName, navItemText) =>
                                    handleSlideMenuChange(
                                        navItemName,
                                        navItemText,
                                        false,
                                    )
                                }
                                logSideNavRedirect={(
                                    redirectionInfo: RedirectionInfo,
                                ) =>
                                    logAnalyticsSideNavRedirect(
                                        redirectionInfo,
                                        isInMemberArea,
                                        logMenuChange,
                                    )
                                }
                                slideMenuState={slideMenu}
                            />
                        )}
                </StyledGrid>
            </div>

            <StickyNavigationButton
                buttonText={stickyButton.text}
                href={stickyButton.href}
                onClick={onCtaClick}
            />
        </Drawer>
    );
}
