import { AppBar } from '@material-ui/core';
import styled from 'styled-components';

import { palette } from '../../theme';
import { ButtonBase } from '../Button';

interface StyledAppBarProperties {
    $hasSnackBar: boolean;
    $appBannerVisible: boolean;
    $showBorderBottom: boolean;
}

export const StyledAppBar = styled(AppBar)<StyledAppBarProperties>`
    top: ${properties => (properties.$appBannerVisible ? '55px' : '0px')};
    height: ${({ $hasSnackBar }) => ($hasSnackBar ? '9.6rem' : '6rem')};
    background-color: ${palette.primary.main};
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        height: ${({ $hasSnackBar }) => ($hasSnackBar ? '11.8rem' : '7.2rem')};
    }
    border-bottom: ${({ $showBorderBottom, $hasSnackBar, theme }) =>
        $showBorderBottom && !$hasSnackBar
            ? `1px solid ${palette.grey[200]}`
            : '0'};
    box-shadow: none;
`;

export const StyledLogoContainer = styled.div`
    margin-left: 1.3rem;
    ${({ theme }) => theme.breakpoints.up(375)} {
        margin-left: 1.5rem;
    }
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-left: 11.5rem;
    }
`;

export const StyledLinksContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    height: 100%;
    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-right: 9.1rem;
    }
`;

export const StyledButton = styled(ButtonBase)`
    margin-right: 2rem;
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 100%;
`;

export const StyledSnackBarContainer = styled.div`
    height: 4.6rem;
`;
