import QRCode from 'react-qr-code';
import styled from 'styled-components';

import { Heading } from '../Heading';

export const StyledOverlayHeading = styled(Heading)`
    font-size: 1.9rem;
    line-height: 2.2rem;
    && {
        margin-bottom: 2rem;
    }

    text-align: center;
`;

export const StyledParagraph = styled.p`
    line-height: 2rem;
    text-align: center;
`;

export const StyledQRBorder = styled.div`
    width: 17rem;
    height: 17rem;
    margin: auto;
    border: 0.7rem solid #000;
    border-radius: 1rem;
`;

export const StyledQRCode = styled(QRCode)`
    display: block;
    width: 80%;
    height: 80%;
    margin: 1.6rem auto 0 auto;
`;

export const StyledLink = styled.a`
    font-weight: bold;
`;
