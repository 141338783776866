/* istanbul ignore file */
import { Typography as MaterialTypography } from '@material-ui/core';
import styled from 'styled-components';

import { palette, Theme } from '../../theme';
import { StyledHeadingProperties } from './Heading.types';

export const StyledHeading = styled(
    MaterialTypography,
)<StyledHeadingProperties>`
    margin-right: 0;
    margin-left: 0;
    padding: ${({ $addPageContainerStyling }) =>
        $addPageContainerStyling ? '0 3rem' : '0'};
    color: ${({ $color }) => $color || palette.primary.main};
    font-weight: normal;
    ${({ $isLowercase }) => ($isLowercase ? 'text-transform: lowercase;' : '')}

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        padding: ${({ $addPageContainerStyling, $alignOnDesktop }) =>
            $addPageContainerStyling && $alignOnDesktop === 'left'
                ? '0 3rem'
                : '0'};
        text-align: ${({ $alignOnDesktop }) => $alignOnDesktop || 'inherit'};
        ${({ $alignOnDesktop }) => ($alignOnDesktop ? `width: 100%;` : ``)}
    }
`;
