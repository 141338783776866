/* eslint-disable unicorn/prefer-math-trunc */
/* eslint-disable no-bitwise */
import useMediaQuery from '@material-ui/core/useMediaQuery';

import { palette, theme } from '../../theme';
import { paths } from './Icon.paths';
import { PathWrapper, StyledSvg } from './Icon.styled';
import { IconProperties } from './Icon.types';

/**
 * An image representing functionality, product or action.
 *
 * All icons are output as SVGs.
 */

export function Icon({
    name,
    rectangle,
    color = palette.primary.main,
    size,
    accessibleTitle,
    onClick,
    onKeyDown,
    className,
    tabIndex,
    viewBox = '0 0 22 22',
    children,
}: IconProperties) {
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));
    const icon = paths[name];

    if (!icon) {
        return null;
    }

    const iconPath = icon;

    const rectangleViewBox = '0 0 50 30';

    // if you pass size as a tuple, we can do different icon sizes
    // for two different breakpoints
    const iconSize = Array.isArray(size) ? size[~~isDesktop] : size;

    return (
        <StyledSvg
            viewBox={rectangle ? rectangleViewBox : viewBox}
            width={iconSize}
            height={iconSize}
            accessibleTitle={accessibleTitle}
            onClick={onClick}
            onKeyDown={onKeyDown}
            className={className}
            tabIndex={tabIndex}
            data-testid={`icon-${name as string}`}
        >
            <PathWrapper
                fill={color}
                stroke="none"
                stroke-width="1"
                fillRule="evenodd"
            >
                {iconPath}
            </PathWrapper>
            {children}
        </StyledSvg>
    );
}

export default Icon;
