import getConfig from 'next/config';
import { v4 as uuidv4 } from 'uuid';

import {
    AVAILABLE_COOKIES,
    AnyContext,
    createCookieApi,
    getCookieSsr,
    getUserIdentifierCookieSsr,
    setUserIdentifierCookieSsr,
    userIdentifierCookieApi,
} from '../../cookies';
import { getCookie } from '../../cookies/cookies.browser.helpers';
import { type FeatureFlagsRequestOptions } from '../featureFlags.types';

const {
    publicRuntimeConfig: { APP_ENVIRONMENT },
} = getConfig();

export const flagsCookieApi = createCookieApi<string>(
    'common',
    AVAILABLE_COOKIES.FEATURE_FLAGS,
    '',
    {
        expires: 365, // 365 days - When cookie is expired, the browser should take care of cookie cleanup.
        sameSite: 'strict',
        secure: true,
        httpOnly: true,
    },
    APP_ENVIRONMENT,
);

export const debugUserId = 'debug';

export const getOptimizelyUserId = (
    context: AnyContext,
    options: FeatureFlagsRequestOptions,
) => {
    try {
        const gaUserIdFromCookie = getCookieSsr<string>({
            context,
            cookieKey: AVAILABLE_COOKIES.GA4_USER_ID,
        });

        const optiUserIdFromCookie = getCookieSsr<string>({
            context,
            cookieKey: AVAILABLE_COOKIES.OPTIMIZELY_USER_ID,
        });

        const fallbackUserIdFromCookie = getUserIdentifierCookieSsr(context);

        const userId =
            optiUserIdFromCookie ||
            gaUserIdFromCookie ||
            fallbackUserIdFromCookie ||
            (uuidv4() as string);

        if (!fallbackUserIdFromCookie || fallbackUserIdFromCookie !== userId)
            setUserIdentifierCookieSsr(context, userId);

        return userId;
    } catch (error) /* istanbul ignore next */ {
        options.logger.error(
            `Error getting Optimizely userId: ${(error as Error).message}`,
            {
                correlationId: 'root',
            },
        );
        return 'unresolved';
    }
};

export const getOptimizelyUserIdClientSide = (
    options: FeatureFlagsRequestOptions & { shouldReset?: boolean },
) => {
    try {
        const gaUserIdFromCookie = getCookie<string>({
            cookieKey: AVAILABLE_COOKIES.GA4_USER_ID,
        });

        const optiUserIdFromCookie = getCookie<string>({
            cookieKey: AVAILABLE_COOKIES.OPTIMIZELY_USER_ID,
        });

        const fallbackUserIdFromCookie =
            userIdentifierCookieApi.browser.getCookie();

        const userId =
            optiUserIdFromCookie ||
            gaUserIdFromCookie ||
            fallbackUserIdFromCookie ||
            (uuidv4() as string);

        if (
            options.shouldReset &&
            (!fallbackUserIdFromCookie || fallbackUserIdFromCookie !== userId)
        )
            userIdentifierCookieApi.browser.setCookie(userId);

        return userId;
    } catch (error) /* istanbul ignore next */ {
        options.logger.error(
            `Error getting Optimizely userId: ${(error as Error).message}`,
            {
                correlationId: 'root',
            },
        );
        return uuidv4() as string;
    }
};
