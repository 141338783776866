import jsCookie from 'js-cookie';

import {
    GetCookie,
    SetCookie,
    UpdateCookie,
    AVAILABLE_COOKIES,
    DeleteCookie,
} from './cookies.types';
import { convertCookieOptions, parseCookieUpsert } from './utils/cookies.utils';
import { ApplicationIdentifier } from '@tgg/common-types';

export const getCookie = <C>({ cookieKey }: GetCookie): C | null => {
    const cookieString = jsCookie.get(cookieKey);
    return cookieString
        ? /^["[{]/.test(cookieString)
            ? JSON.parse(cookieString)
            : cookieString
        : null;
};
export const setCookie = <C>({
    cookieKey,
    value,
    cookieParameters,
    environment,
}: SetCookie<C>) => {
    const serializedValue =
        typeof value !== 'string' ? JSON.stringify(value) : value;
    jsCookie.set(
        cookieKey,
        serializedValue,
        convertCookieOptions(cookieParameters, cookieKey, environment),
    );
};

export const updateCookie = <C>({
    cookieKey,
    value,
    cookieParameters,
    environment,
}: UpdateCookie<C>) => {
    const currentValue = getCookie<C>({
        cookieKey,
    });

    const cookieValue = parseCookieUpsert(currentValue, value);

    setCookie<C>({
        cookieKey,
        value: cookieValue,
        cookieParameters: convertCookieOptions(
            cookieParameters,
            cookieKey,
            environment,
        ),
    });
};

export const deleteCookie = <
    T extends ApplicationIdentifier,
    D extends AVAILABLE_COOKIES,
>({
    cookieKey,
    cookieParameters,
    environment,
}: DeleteCookie) => {
    jsCookie.remove(
        cookieKey,
        convertCookieOptions(cookieParameters, cookieKey, environment),
    );
};

export const clearApplicationCookies = (
    applicationIdentifier: ApplicationIdentifier,
) => {
    Object.values(AVAILABLE_COOKIES).forEach(cookie => {
        deleteCookie({
            cookieKey: `${cookie}-${applicationIdentifier}`,
            cookieParameters: {},
        });
    });
};
