import Link from 'next/link';

import { useHover, useDynamicsStyle } from './IconLink.hooks';
import { StyledIcon, StyledLink, StyledButton } from './IconLink.styled';
import { IconLinkProperties } from './IconLink.types';
import { isSupportCentre } from '@tgg/util';

export function IconLink({
    text,
    link,
    href,
    truncate,
    buttonOnClick,
    style,
    hoverStyle,
    ariaLabel,
    ...iconProperties
}: IconLinkProperties) {
    const [linkReference, isLinkHovering] = useHover<HTMLAnchorElement>();
    const [buttonReference, isButtonHovering] = useHover<HTMLButtonElement>();
    const dynamicStyleLink = useDynamicsStyle(
        style,
        hoverStyle,
        isLinkHovering,
    );

    const dynamicStyleButton = useDynamicsStyle(
        style,
        hoverStyle,
        isButtonHovering,
    );

    const linkProperties = {
        ref: linkReference,
        $background: dynamicStyleLink.background,
        tabIndex: 0,
    };

    if (buttonOnClick) {
        return (
            <StyledButton
                $background={dynamicStyleButton.background}
                onClick={buttonOnClick}
                ref={buttonReference}
                aria-label={ariaLabel}
                type="button"
            >
                <StyledIcon
                    {...iconProperties}
                    color={dynamicStyleButton.iconColor}
                />
            </StyledButton>
        );
    }
    if (link) {
        const isSupportCentreLink = isSupportCentre(link);
        return (
            <Link href={link} passHref legacyBehavior>
                <StyledLink
                    {...linkProperties}
                    aria-label={ariaLabel}
                    title={ariaLabel}
                    rel={isSupportCentreLink ? 'noopener noreferrer' : ''}
                >
                    <StyledIcon
                        {...iconProperties}
                        color={dynamicStyleLink.iconColor}
                    />
                </StyledLink>
            </Link>
        );
    }

    return (
        <StyledLink {...linkProperties} href={href}>
            <StyledIcon
                {...iconProperties}
                color={dynamicStyleLink.iconColor}
            />
        </StyledLink>
    );
}

export default IconLink;
