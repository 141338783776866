/* istanbul ignore file */
import { createContext } from 'react';

import { appConfig } from './appConfigDefaults';
import { ApplicationIdentifier } from '@tgg/common-types';

export type AppConfigProperties = {
    appConfig: { appIdentifier: ApplicationIdentifier | 'unresolved' };
};

export const AppConfigContext = createContext<AppConfigProperties>({
    appConfig,
});
