/* istanbul ignore file */
import styled from 'styled-components';

import { palette, Theme } from '../../theme';
import { HorizontalRule } from '../HorizontalRule';
import {
    StyledSubMenuTextProperties,
    StyledLinkContainerProperties,
    StyledTextProperties,
} from './NavigationItem.types';

export const StyledListItem = styled.li`
    height: 5rem;
`;

export const StyledLinkContainer = styled.a<StyledLinkContainerProperties>`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: ${({ $leftIconExists }) =>
        $leftIconExists ? '1.2rem' : '4.4rem'};
    color: ${palette.common.blue};
    text-decoration: none;

    & :hover {
        text-decoration: underline;
    }

    ${({ theme }) => theme.breakpoints.up(375)} {
        margin-left: ${({ $leftIconExists }) =>
            $leftIconExists ? '4.4rem' : '5rem'};
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-left: 5.1rem;
    }
`;

export const StyledText = styled.span<StyledTextProperties>`
    width: 24.8rem;
    margin: 0 0.4rem 0 1.5rem;
    padding-top: ${({ theme }) => theme.spacing(14)};
    padding-bottom: ${({ theme }) => theme.spacing(10)};
    font-weight: normal;
    font-size: 1.4rem;
    text-transform: ${({ $caseSensitive }) =>
        $caseSensitive ? 'none' : 'lowercase'};

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
    }
`;

export const StyledSubMenuText = styled.span<StyledSubMenuTextProperties>`
    margin-left: ${({ $isSubMenuAlternativeItem }) =>
        $isSubMenuAlternativeItem && '0.8rem'};
    padding-top: ${({ theme }) => theme.spacing(15)};
    padding-bottom: ${({ theme }) => theme.spacing(15)};
    font-weight: normal;
    font-size: 1.4rem;
    text-transform: ${({ $caseSensitive }) =>
        $caseSensitive ? 'none' : 'lowercase'};
`;

export const StyledContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 1.2rem;
    color: ${palette.common.blue};
    cursor: pointer;

    & :hover {
        text-decoration: underline;
    }

    ${({ theme }) => theme.breakpoints.up(375)} {
        margin-left: 4.4rem;
    }

    ${({ theme }) => theme.breakpoints.up('desktop')} {
        margin-left: 5.1rem;
    }
`;

export const StyledHorizontalRule = styled(HorizontalRule)`
    width: 27rem;
    margin: 0 0 0 5rem;
    background-color: ${palette.grey['900']};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up(375)} {
        width: 32.5rem;
    }
`;
