import { type OptimizelyDecision } from '@optimizely/optimizely-sdk';
import { createHash } from 'crypto';
import { type NextPageContext, type GetServerSidePropsContext } from 'next';

import { flagsCookieApi } from '../cookie/featureFlagsCookie';
import { defaultFlags } from '../defaultFlags';
import { type TggFlagsObject } from '../featureFlags.types';
import { type OptimizelyFeatureFlags } from '@tgg/common-types';

export const mapOptimizelyFlag = (flag: OptimizelyDecision) => {
    return {
        enabled:
            flag.enabled ??
            /* istanbul ignore next */
            defaultFlags[flag.flagKey as OptimizelyFeatureFlags].enabled,
        variationKey:
            flag.variationKey ??
            /* istanbul ignore next */
            defaultFlags[flag.flagKey as OptimizelyFeatureFlags]?.variationKey,
        variables:
            flag.variables ??
            /* istanbul ignore next */
            defaultFlags[flag.flagKey as OptimizelyFeatureFlags]?.variables,
        ruleKey: flag.ruleKey,
    };
};

export const mapOptimizelyFlags = (
    flagsObject: Record<OptimizelyFeatureFlags, OptimizelyDecision>,
) =>
    Object.entries(flagsObject).reduce((accumulator, entry) => {
        const newAccumulator = { ...accumulator };
        // @ts-ignore
        newAccumulator[entry[0] as OptimizelyFeatureFlags] = mapOptimizelyFlag(
            entry[1],
        );
        return newAccumulator;
    }, {} as TggFlagsObject);

/* Deprecated - cache is cleared on flag change by Optimizely client */
/* istanbul ignore next */
export const diffFlagsHashAndUpdateCookie = (
    context: GetServerSidePropsContext | NextPageContext,
    flags: TggFlagsObject,
) => {
    const currentFlagsHash = createHash('md5')
        .update(JSON.stringify(flags))
        .digest('hex');

    const cachedFlagsHash = flagsCookieApi.ssr.getCookieSsr(context);

    // Set new cookie value when flags change detected to force cache invalidation
    /* istanbul ignore else */
    if (currentFlagsHash !== cachedFlagsHash) {
        flagsCookieApi.ssr.setCookieSsr(context, currentFlagsHash);
    }
};
