import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { upperFirst } from 'lodash';

import { TggTrace, TggTraceCorrelationParameters } from '../logger.types';
import { ApplicationIdentifier } from '@tgg/common-types';

const clientText = 'Browser Clientside';
const serverText = 'Next.js Serverside';

const getApplicationName = (applicationIdentifier: ApplicationIdentifier) =>
    applicationIdentifier === 'member'
        ? 'Member Area'
        : upperFirst(applicationIdentifier);

export const getFullApplicationIdentifierClient = (
    applicationIdentifier: ApplicationIdentifier,
) => `TGG ${getApplicationName(applicationIdentifier)} - ${clientText}`;

export const getFullApplicationIdentifierServer = (
    applicationIdentifier: ApplicationIdentifier,
) => `TGG ${getApplicationName(applicationIdentifier)} - ${serverText}`;

export const createTraceMessage = (
    message: string,
    severityLevel: SeverityLevel,
    parameters: TggTraceCorrelationParameters,
    options: {
        applicationIdentifier: ApplicationIdentifier;
        type: 'SERVER' | 'CLIENT';
    },
): TggTrace => {
    const { type, applicationIdentifier } = options;
    const { journeyInfo, ...restParameters } = parameters;
    const traceJourneyInfo = journeyInfo
        ? `journey: ${journeyInfo.journeyName} ${journeyInfo.journeyStep ?? ''}`
        : 'journey: unknown';
    const traceMessage = `**${applicationIdentifier}** - ${type}: 
    ${message}
  
    ${traceJourneyInfo}

    meta: ${JSON.stringify(restParameters, null, 2)}
    `;

    const traceLabel =
        type === 'CLIENT'
            ? getFullApplicationIdentifierClient(applicationIdentifier)
            : getFullApplicationIdentifierServer(applicationIdentifier);

    const agent = type === 'CLIENT' ? window.navigator.userAgent : 'Next proxy';

    return {
        message: traceMessage,
        severityLevel,
        properties: {
            Application: traceLabel,
            UserAgent: agent,
        },
    };
};
