import styled from 'styled-components';

import { palette } from '../../theme';
import { StyledChipWrapperProperties } from './Chip.types';

export const ChipWrapper = styled.span<StyledChipWrapperProperties>`
    display: inline-block;
    box-sizing: border-box;
    height: 2rem;
    padding: 0 0.7rem;
    color: ${palette.common.blue};
    font-size: 1.2rem;
    line-height: 1.8rem;
    text-transform: lowercase;
    vertical-align: middle;
    border: 1px solid;
    border-color: ${palette.common.blue};
    border-radius: 1rem;
`;
