/* istanbul ignore file */
import styled from 'styled-components';

import { Theme } from '../../theme';

interface StyledMainProperties {
    $hasSnackBar?: boolean;
    $appBannerVisible?: boolean;
    $isMobileSession?: boolean;
    $isMobileApp?: boolean;
}

export const StyledMain = styled.main<StyledMainProperties>`
    margin-top: ${properties =>
        properties.$hasSnackBar
            ? '9.4rem'
            : properties.$isMobileSession
            ? 0
            : '6rem'};
    padding-top: ${properties =>
        properties.$appBannerVisible ? '55px' : '0px'};

    ${({ theme }: { theme: Theme }) => theme.breakpoints.up('desktop')} {
        margin-top: ${properties =>
            properties.$hasSnackBar
                ? '11.6rem'
                : properties.$isMobileSession
                ? 0
                : '7.2rem'};
    }
`;
