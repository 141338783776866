import styled from 'styled-components';

import { Palette } from '../../theme';

export const StyledWrapper = styled.div<{ $backgroundColor: Palette }>`
    position: sticky;
    bottom: 0;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    padding: ${({ theme }) => theme.spacing(30)};
    background-color: ${({ $backgroundColor }) => `${$backgroundColor}`};

    button {
        text-transform: lowercase;
    }
`;
