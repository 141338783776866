import Link from 'next/link';

import { Palette, palette } from '../../theme';
import { Icon } from '../Icon';
import {
    StyledText,
    StyledListItem,
    StyledContainer,
    StyledHorizontalRule,
    StyledSubMenuText,
    StyledLinkContainer,
} from './NavigationItem.styled';
import { NavigationItemProperties } from './NavigationItem.types';
import { isSupportCentre } from '@tgg/util';

/**
 * Use the `NavigationItem` component as an Item in the Navigation.
 */
export function NavigationItem({
    isSubMenuAlternativeItem = false,
    text,
    leftIconName,
    divider = true,
    onClick,
    href,
    tabIndex,
    caseSensitive = false,
}: NavigationItemProperties) {
    if (href && leftIconName) {
        const isSupportCentreLink = isSupportCentre(href);
        return (
            <StyledListItem>
                <StyledLinkContainer
                    href={href}
                    $leftIconExists
                    {...(onClick && {
                        onClick: () => onClick(),
                    })}
                    tabIndex={tabIndex}
                    rel={isSupportCentreLink ? 'noopener noreferrer' : ''}
                >
                    <Icon
                        name={leftIconName}
                        color={palette.primary.main as Palette}
                    />

                    {isSubMenuAlternativeItem ? (
                        <StyledSubMenuText
                            $caseSensitive={caseSensitive}
                            $isSubMenuAlternativeItem
                        >
                            {text}
                        </StyledSubMenuText>
                    ) : (
                        <StyledText $caseSensitive={caseSensitive}>
                            {text}
                        </StyledText>
                    )}
                </StyledLinkContainer>
                {divider && <StyledHorizontalRule />}
            </StyledListItem>
        );
    }
    if (onClick && leftIconName) {
        return (
            <StyledListItem>
                <StyledContainer
                    onClick={event => {
                        event.preventDefault();
                        onClick();
                    }}
                    onKeyDown={event => {
                        if (event.key === 'Enter') {
                            event.preventDefault();
                            onClick();
                        }
                    }}
                    tabIndex={tabIndex}
                    role="button"
                    aria-pressed={false}
                >
                    <Icon
                        name={leftIconName}
                        color={palette.primary.main as Palette}
                    />

                    <StyledText $caseSensitive={caseSensitive}>
                        {text}
                    </StyledText>

                    <Icon
                        name="chevronRight"
                        size={22}
                        color={palette.primary.main as Palette}
                    />
                </StyledContainer>
                {divider && <StyledHorizontalRule />}
            </StyledListItem>
        );
    }
    if (href) {
        return (
            <StyledListItem>
                <Link href={href} passHref legacyBehavior>
                    <StyledLinkContainer
                        {...(onClick && {
                            onClick: () => onClick(),
                        })}
                    >
                        <StyledSubMenuText $caseSensitive={caseSensitive}>
                            {text}
                        </StyledSubMenuText>
                    </StyledLinkContainer>
                </Link>
                {divider && <StyledHorizontalRule />}
            </StyledListItem>
        );
    }

    return (
        <StyledListItem>
            <StyledSubMenuText $caseSensitive={caseSensitive}>
                {text}
            </StyledSubMenuText>
            {divider && <StyledHorizontalRule />}
        </StyledListItem>
    );
}
