import styled from 'styled-components';

import { palette } from '../../theme';

export const StyledWrapper = styled.a`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: ${({ theme }) => `${theme.spacing(42)}`};
    height: ${({ theme }) => `${theme.spacing(42)}`};
    background-color: ${palette.primary.main};
    border-radius: 50%;
    cursor: pointer;

    &:hover {
        background-color: ${palette.primary.dark};
    }
`;
