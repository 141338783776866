/* istanbul ignore file */
import { Typography as MaterialTypography } from '@material-ui/core';
import styled from 'styled-components';

import { theme, palette } from '../../theme';
import { StyledParagraphProperties } from './Paragraph.types';

export const StyledParagraph = styled(
    MaterialTypography,
)<StyledParagraphProperties>`
    a {
        color: ${palette.common.blue};
        text-decoration: underline;
    }

    font-size: 1.4rem;
    line-height: 2rem;

    ${theme.breakpoints.up('desktop')} {
        font-size: 1.6rem;
        line-height: 2.2rem;
    }

    ${({ level }) => {
        if (level === 'body-lg') {
            return `
                font-size: 1.8rem;
                line-height: 2.6rem;
            `;
        }

        if (level === 'body-md') {
            return `
                font-size: 1.6rem;
                line-height: 2.2rem;
            `;
        }

        if (level === 'body-sm') {
            return `
                font-size: 1.4rem;
                line-height: 2rem;

                ${theme.breakpoints.up('desktop')} {
                    font-size: 1.2rem;
                    line-height: 2.2rem;
                }
            `;
        }

        if (level === 'body-xs') {
            return `
                font-size: 1rem;
                line-height: 1.4rem;
            `;
        }

        return ``;
    }};
`;
