/* istanbul ignore file */

import { createGlobalStyle } from 'styled-components';

export const Fonts = createGlobalStyle<{ baseUrl?: string }>`
    @font-face {
        font-weight: bold;
        font-family: 'thegymgroupsans-Bold';
        font-style: normal;
        font-display: swap;
        unicode-range: U+000-5FF;
        src: ${properties => {
            const baseUrl = properties.baseUrl ? `${properties.baseUrl}/` : '/';
            const location = `${baseUrl}fonts`;

            return `local('thegymgroupsans-Bold'), url('${location}/thegymgroupsans-Bold.woff2') format('woff2'), url('${location}/thegymgroupsans-Bold.woff') format('woff');`;
        }}
    }
    @font-face {
        font-weight: 500;
        font-family: 'thegymgroupsans-BoldItalic';
        font-style: italic;
        font-display: swap;
        unicode-range: U+000-5FF;
        src: ${properties => {
            const baseUrl = properties.baseUrl ? `${properties.baseUrl}/` : '/';
            const location = `${baseUrl}fonts`;

            return `local('thegymgroupsans-BoldItalic'), url('${location}/thegymgroupsans-BoldItalic.woff2') format('woff2'), url('${location}/thegymgroupsans-BoldItalic.woff') format('woff');`;
        }}
    }
    
    @font-face {
        font-weight: 500;
        font-family: 'thegymgroupsans-Headline';
        font-style: normal;
        font-display: swap;
        unicode-range: U+000-5FF;
        src: ${properties => {
            const baseUrl = properties.baseUrl ? `${properties.baseUrl}/` : '/';
            const location = `${baseUrl}fonts`;

            return `local('thegymgroupsans-Headline'), url('${location}/thegymgroupsans-Headline.woff2') format('woff2'), url('${location}/thegymgroupsans-Headline.woff') format('woff');`;
        }}
    }
`;
