import { Dialog, useMediaQuery } from '@material-ui/core';

import { palette, theme } from '../../theme';
import {
    StyledDialogTitle,
    StyledDialogContent,
    StyledCloseButton,
    StyledIcon,
    StyledHeading,
} from './Overlay.styled';
import { OverlayProperties } from './Overlay.types';

export const Overlay = ({
    title,
    children,
    open,
    contentContainerHasPadding = true,
    contentContainerisFullHeight = false,
    titleVariant = 'h2',
    handleClose,
    centerTitleOnDesktop,
    contentContainerMaxWidth,
}: OverlayProperties) => {
    const fullScreen = useMediaQuery('(max-width:767px)');
    const isDesktop = useMediaQuery(theme.breakpoints.up('desktop'));

    return (
        <Dialog
            open={open}
            fullScreen={fullScreen}
            onClose={handleClose}
            aria-labelledby="overlay"
            aria-describedby="overlay-modal"
            PaperProps={{
                style: {
                    borderRadius: isDesktop ? '16px' : 'unset',
                    overflowX: 'hidden',
                },
            }}
        >
            <StyledDialogTitle>
                <StyledHeading
                    variant={titleVariant}
                    component="h1"
                    gutterBottom={false}
                    alignOnDesktop={centerTitleOnDesktop ? 'center' : undefined}
                    {...(contentContainerMaxWidth
                        ? { $maxWidth: contentContainerMaxWidth }
                        : {})}
                >
                    {title}
                </StyledHeading>
                <StyledCloseButton
                    onClick={handleClose}
                    aria-label="Close"
                    name="Close"
                >
                    <StyledIcon name="close" color={palette.common.white} />
                </StyledCloseButton>
            </StyledDialogTitle>
            <StyledDialogContent
                $hasPadding={contentContainerHasPadding}
                $isFullHeight={contentContainerisFullHeight}
                {...(contentContainerMaxWidth
                    ? { $maxWidth: contentContainerMaxWidth }
                    : {})}
            >
                {children}
            </StyledDialogContent>
        </Dialog>
    );
};

export default Overlay;
