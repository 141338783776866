import { TggLogger } from '../logger';
import { OptimizelyFeatureFlags } from '@tgg/common-types';

export type FeatureFlagsRequestOptions = {
    logger: TggLogger;
};

export type TggFlag = { enabled: boolean; variationKey?: string | null };

export type OptimizelyFlag<
    Variation extends string | null = null,
    Variables extends Record<string, unknown> | null = null,
> = TggFlag & {
    variationKey?: Variation;
    variables?: Variables;
    ruleKey?: string | null;
};

interface FlagTypeMap {
    [OptimizelyFeatureFlags.JJ_ADVISORY_MESSAGE_ENABLED]: JjAdvisoryMessageEnabledFlag;
    [OptimizelyFeatureFlags.MEMBERSHIP_TERMS_VISIBILITY]: MembershipTermsVisibilityFlag;
    [OptimizelyFeatureFlags.SAVINGS_VISIBILITY_VARIATIONS]: SavingsVisibilityVariationsFlag;
    [OptimizelyFeatureFlags.AA_TEST]: AATestFlag;
    [OptimizelyFeatureFlags.API_VERSION_3_ENABLED]: ApiVersion3EnabledFlag;
}

export type FlagObject<T extends OptimizelyFeatureFlags> =
    T extends keyof FlagTypeMap ? FlagTypeMap[T] : OptimizelyFlag;

export type TggFlagsObject = {
    [key in OptimizelyFeatureFlags]: FlagObject<key>;
};

// Flags with A/B support
export type SavingsVisibilityVariations = 'off' | 'v1' | 'v2' | 'v3';
export type SavingsVisibilityVariationsFlag =
    OptimizelyFlag<SavingsVisibilityVariations>;

export type JjAdvisoryMessageEnabledVariations = 'top' | 'bottom';
export type JjAdvisoryMessageEnabledFlag =
    OptimizelyFlag<JjAdvisoryMessageEnabledVariations>;

export type MembershipTermsVisibilityVariations = 'Control' | 'V1';
export type MembershipTermsVisibilityFlag =
    OptimizelyFlag<MembershipTermsVisibilityVariations>;

export type AATestVariations = 'FXAA_control' | 'FXAA_v1' | 'FXAA_v2';
export type AATestFlag = OptimizelyFlag<
    AATestVariations,
    { enableDataLayer: boolean }
>;

export type ApiVersion3EnabledVariations = 'on' | 'off';
export type ApiVersion3EnabledFlag =
    OptimizelyFlag<ApiVersion3EnabledVariations>;

// Tracking

export enum OptimizelyVisitEvents {
    'VISIT_YOUR_MEMBERSHIP' = 'your_membership_visit',
    'VISIT_YOUR_PAYMENTS' = 'your_payments_visit',
    'VISIT_GYM_PAGE' = 'gym_page_visit',
}

export enum OptimizelyGlobalEvents {
    'START_JOIN_JOURNEY' = 'jj_start',
    'PURCHASE' = 'purchase',
}

export enum FlagsConstants {
    UNRESOLVED = 'unresolved',
}

export const OptimizelyEvents = {
    ...OptimizelyVisitEvents,
    ...OptimizelyGlobalEvents,
};

export type OptimizelyEventKey = keyof typeof OptimizelyEvents;
export type OptimizelyEvent = typeof OptimizelyEvents[OptimizelyEventKey];

export type OptimizelyDecisionEvent = {
    'optimizely-flagKey': OptimizelyFeatureFlags;
    'optimizely-ruleKey': string;
    'optimizely-variationKey': string;
    'optimizely-userId': string;
    'optimizely-resolvedPath': string;
};
